/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
/* angular-cli file: src/styles.css */
/*@import "../node_modules/angular-calendar/css/angular-calendar.css";*/
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';


@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';




@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';



.dz-size {
    display: none !important;
}

.dz-message {
    position: relative;
    display: inline-block;
    overflow: inherit !important;
    min-height: inherit !important;
    max-height: 100%;
    border: none !important;
    background: none !important;
}

.dz-image img {
    height: 120px;
    width: 120px;
    object-fit: contain;
}

.dz-download {
    padding-left: 15px;
    cursor: pointer !important;
}

.dz-download:hover {
    text-decoration: underline;
}

.dropzone-area {
    margin: inherit !important;
    padding: 15px;
    border: 1px dashed rgba(0, 0, 0, .3);
    border-radius: 5px;
}

.smart-form .select i.override {
    top: 29px;
}

.align-center {
    text-align: center;
}

.dt-toolbar {
    padding: 6px 7px 6px !important;
}

.background-grey {
    background-color: rgba(0, 0, 0, .1) !important
}

/*lib overide*/

.label-default {
    /*background-color: #999 !important;*/
    color: #999;
    background: transparent;
    border: 1px solid #999;
}

.label-primary {
    color: #3276b1;
    background: transparent;
    border: 1px solid #3276b1;
}

.label-success {
    color: #739e73;
    background: transparent;
    border: 1px solid #739e73;
}

.label-warning {
    color: #c79121;
    background: transparent;
    border: 1px solid #c79121;
}

.label-info {
    color: #57889c;
    background: transparent;
    border: 1px solid #57889c;
}

.label-danger {
    color: #a90329;
    background: transparent;
    border: 1px solid #a90329;
}

.cursor-pointer {
    color: #03b3f6;
}

.cursor-pointer:hover {
    cursor: pointer;
    color: #03b3f6;
    border-bottom: 1px solid #03b3f6;
}

/*.smart-form .label{*/
/*text-align: center !important;*/
/*}*/

/*.smart-form .toggle{*/
/*padding-right:28px;*/
/*}*/

/*.smart-form .toggle i{*/
/*left: 0;*/
/*top: 2px;*/
/*}*/

.my-smart-form .label {
    text-align: center !important;
}

.my-smart-form .toggle {
    padding-right: 28px;
}

.my-smart-form .toggle i {
    left: 0;
    top: 2px;
}

.common_status .label {
    text-align: center !important;
}

.common_status .toggle {
    padding-right: 28px;
}

.common_status .toggle i {
    left: -1px;
    top: 0;
    width: 62px;
}

.common_status .toggle i:before {
    background-color: gray;
}

.common_status .toggle input:checked + i:before {
    right: 49px;
    background-color: blue;
}

.common_status .checkbox input:checked + i, .smart-form .radio input:checked + i, .smart-form .toggle input:checked + i {
    border-color: blue;
}

.common_yesno .label {
    text-align: center !important;
}

.common_yesno .toggle {
    padding-right: 28px;
}

.common_yesno .toggle i {
    left: 0;
    top: 0;
}

.common_yesno .toggle i:before {
    background-color: gray;
}

.common_yesno .toggle input:checked + i:before {
    background-color: blue;
}

.common_yesno .checkbox input:checked + i, .smart-form .radio input:checked + i, .smart-form .toggle input:checked + i {
    border-color: blue;
}


.background-grey {
    background-color: #bfbfbf !important;
}

i.caret pull-right {
    display: none !important;
}

.p-chips > .ui-inputtext {
    width: 100%;
    height: 100px;
}
